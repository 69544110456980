<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div class="info-list">
          <ul>
            <li v-for="(item, index) in bioList" :key="index">
              <a @click="goThere(item)">
                <img :src="item.servicePath + item.cover" />
              </a>
              <p>
                {{ item.brief }}
                <span>more</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      bioList: [],
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  watch: {
    $route() {
      this.getList()
    },
    getSubMenu: {
      deep: true,
      handler() {
        this.getList()
      }
    }
  },
  methods: {
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: 999,
          pageNo: 1,
        }).then((res) => {
          this.bioList = res ? res.list : [];
        });
      }
    },
    goThere(item) {
      this.$router.push({
        name: "mnewsInfo",
        query: {
          id: item.id,
          key: this.$route.name,
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
      .info-list {
        ul {
          list-style: none;
          overflow: hidden;
          li {
            width: 100%;
            height: 10.867rem;
            margin-bottom: 0.5rem;
            position: relative;
            a {
              color: #333;
              img {
                display: block;
                width: auto;
                height: 10.867rem;
              }
            }
            p {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              padding: 0.3rem 3%;
              box-sizing: border-box;
              background: rgba(0, 0, 0, 0.4);
              color: #ffffff;
              font-size: 0.577rem;
              span {
                float: right;
              }
            }
          }
        }
      }
    }
  }
}
</style>